var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mx-auto"},[_c('b-card',[_c('b-row',{staticClass:"pb-50 mt-50 border-bottom pb-1 mb-2"},[_c('b-col',{staticClass:"d-flex justify-content-between justify-content-sm-start mb-2",attrs:{"sm":"8"}},[_c('b-link',{staticClass:"d-flex align-items-center text-nowrap"},[(this.data.totalComments == 0)?_c('span',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.$t("profileDetails.Be_first_comment")))]):_c('span',{staticClass:"text-primary",on:{"click":_vm.showAllCommentsFun}},[_vm._v(" "+_vm._s(_vm.$t("profileDetails.All_Comments")))])])],1),_c('b-col',{staticClass:"d-flex justify-content-between justify-content-sm-end align-items-center mb-2",attrs:{"sm":"4"}},[_c('b-link',{class:{
            'text-nowrap': true,
            'text-muted': _vm.data.totalComments < 1,
            'text-primary': _vm.data.totalComments,
          }},[_c('feather-icon',{staticClass:"profile-icon mr-50",attrs:{"icon":"MessageSquareIcon","size":"18"}}),_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.kFormatter(this.data.totalComments)))])],1)],1)],1),(_vm.showAllComments)?_c('div',{staticClass:"allComments"},_vm._l((_vm.data.comments),function(comment,ind){return _c('div',{key:ind,staticClass:"d-flex align-items-start mb-1"},[_c('b-link',{attrs:{"to":("/profile/" + (comment.userId.username))}},[_c('b-avatar',{staticClass:"mt-25 mr-75",attrs:{"src":comment.userId.profileImage,"size":"34"}})],1),_c('div',{staticClass:"profile-user-info w-100"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('h6',{staticClass:"mb-0"},[_c('b-link',{attrs:{"to":("/profile/" + (comment.userId.username))}},[_vm._v(_vm._s(comment.userId.username))]),_vm._v(" ⸱ "),_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.moment(comment.createdAt).fromNow(false)))])],1),_c('div',{staticClass:"text-body"},[(
                  _vm.$store.getters['auth/isAuth'] &&
                  _vm.$store.getters['auth/user']._id == comment.userId._id
                )?_c('feather-icon',{staticClass:"mr-25",class:comment.youLiked ? 'profile-likes' : 'profile-icon',attrs:{"icon":"TrashIcon","size":"18"},on:{"click":function($event){return _vm.deleteComment(comment._id)}}}):_vm._e()],1)]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.filterComment(comment.comment))}})])],1)}),0):_vm._e(),(!_vm.$store.getters['auth/isAuth'])?_c('div',{staticClass:"text-center py-1 text-muted"},[_c('b-link',{attrs:{"to":"/login"}},[_vm._v(_vm._s(_vm.$t("profileDetails.Login")))]),_vm._v(" "+_vm._s(_vm.$t("profileDetails.to_Comment"))+" ")],1):_vm._e(),(_vm.$store.getters['auth/isAuth'])?_c('div',[_c('b-form-group',[_c('b-input-group',{scopedSlots:_vm._u([(_vm.commentText != '')?{key:"append",fn:function(){return [_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"ml-1",attrs:{"size":"sm","variant":"primary","disabled":_vm.isLoading},on:{"click":_vm.postComment}},[_c('feather-icon',{attrs:{"icon":"SendIcon","size":"20"}})],1)]},proxy:true}:null],null,true)},[_c('b-form-textarea',{attrs:{"rows":"1","placeholder":_vm.$t('profileDetails.Add_Comment'),"disabled":_vm.isLoading},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.postComment.apply(null, arguments)}},model:{value:(_vm.commentText),callback:function ($$v) {_vm.commentText=$$v},expression:"commentText"}})],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }