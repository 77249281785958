<template>
  <div>
    <section class="counter">
      <div class="days mr-1 relative">
        <div class="time">{{ displayDays }} <span>:</span></div>
        <div class="label">days</div>
      </div>
      <!-- <span class="">:</span> -->
      <div class="hours mr-1">
        <div class="time">{{ displayHours }} <span>:</span></div>

        <div class="label">hours</div>
      </div>
      <!-- <span class="">:</span> -->
      <div class="minutes mr-1">
        <div class="time">{{ displayMinutes }} <span>:</span></div>

        <div class="label">minutes</div>
      </div>
      <!-- <span class="">:</span> -->
      <div class="seconds mr-1">
        <div class="time">{{ displaySecondes }}</div>
        <div class="label">seconds</div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      displayDays: 0,
      displayHours: 0,
      displayMinutes: 0,
      displaySecondes: 0,
    };
  },
  computed: {
    _seconds: () => 1000,
    _minutes() {
      return this._seconds * 60;
    },
    _hours() {
      return this._minutes * 60;
    },
    _days() {
      return this._hours * 24;
    },
  },
  mounted() {
    this.showRemaining();
  },
  methods: {
    showRemaining() {
      const timer = setInterval(() => {
        const now = new Date();
        const end = new Date(2022, 6, 5, 12, 35, 10, 10);
        //console.log(end);
        const distance = end.getTime() - now.getTime();
        console.log(distance);

        if (distance < 0) {
          clearInterval(timer);
          return "Done";
        }
        const days = Math.floor(distance / this._days);
        const hours = Math.floor((distance % this._days) / this._hours);
        const minutes = Math.floor((distance % this._hours) / this._minutes);
        const seconds = Math.floor((distance % this._minutes) / this._seconds);

        this.displaySecondes = seconds < 10 ? "0" + seconds : seconds;
        this.displayMinutes = minutes < 10 ? "0" + minutes : minutes;
        this.displayHours = hours < 10 ? "0" + hours : hours;
        this.displayDays = days < 10 ? "0" + days : days;
      }, 1000);
    },
  },
};
</script>

<style scoped>
.counter {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.time {
  font-size: 25px;
  font-weight: 500;
}
.label {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
</style>
