<template>
  <div>
    <div>
      <Header class="header-companylist" />

      <div v-if="errorMessage">
        <h4 class="alert alert-danger card-details p-2 mx-3">
          {{ errorMessage }}
          <span>
            <b-link :to="{ name: 'timeline' }">
              <span class="text-dark ml-1">
                {{ $t("common.Back_to_Business") }}</span
              >
            </b-link>
          </span>
        </h4>
      </div>
      <div v-if="!loading" :class="!errorMessage ? 'card-details' : null">
        <skeleton-details-profile
          :loading="!loading"
          :item-number="4"
          :number-in-row="3"
          :height="'1500'"
        />
      </div>
      <section class="app-ecommerce-details" v-if="loading">
        <!-- Alert: No item found -->
        <!-- <b-alert variant="danger" :show="profileDetails._id === null">
        <h4 class="alert-heading">Error fetching product data</h4>
        <div class="alert-body">
          No item found with this item slug. Check
          <b-link class="alert-link" :to="{ name: 'company-list' }">
            Shop
          </b-link>
          for other items.
        </div>
      </b-alert> -->
        <b-container class="card-details">
          <b-row class="my-2">
            <b-col md="7">
              <b-card no-body>
                <b-card-body class="p-0">
                  <div class="details-img-name px-2 pt-2">
                    <div class="mb-1">
                      <img height="150" class="" :src="icon" />
                      <div class="ribbon ribbon-top-right" v-if="isSold">
                        <span>{{ $t("profileDetails.Sold") }}</span>
                      </div>
                    </div>
                    <div class="name-location">
                      <!-- Product Name -->
                      <div>
                        <h3 class="text-primary">{{ profileDetails.name }}</h3>
                        <p class="">
                          <span class="text-muted text-w500">
                            {{ $t("profileDetails.Owned_by") }}</span
                          >
                          <b-link
                            :to="{
                              name: 'userprofile',
                              params: {
                                username: profileDetails.userId.username,
                              },
                            }"
                          >
                            <span class="ownedName">
                              {{ profileDetails.userId.username }}</span
                            >
                          </b-link>
                          <a href=""></a>
                        </p>
                      </div>

                      <div class="location-social">
                        <!-- location -->
                        <b-card-text>
                          <i class="fa-solid fa-location-dot text-primary"></i>
                          <span
                            class="ml05 text-w500"
                            v-if="profileDetails.address.profileCountry"
                            >{{
                              $store.state.locale.locale == "ar"
                                ? profileDetails.address.profileCountry
                                    .arabicName
                                : profileDetails.address.profileCountry.name
                            }}
                          </span>
                          <span
                            class="text-w500"
                            v-if="profileDetails.address.profileState"
                            >{{
                              " - " + profileDetails.address.profileState
                            }}</span
                          >
                        </b-card-text>
                      </div>

                      <!--Socia media-->
                      <div
                        class="contact-social"
                        v-if="profileDetails.socialMedia"
                      >
                        <ul>
                          <li v-if="profileDetails.socialMedia.facebook">
                            <a
                              v-if="profileDetails.socialMedia.facebook"
                              :href="profileDetails.socialMedia.facebook"
                              target="_blank"
                              ><i class="fa-brands fa-facebook"></i
                            ></a>
                          </li>
                          <li v-if="profileDetails.socialMedia.twitter">
                            <a
                              v-if="profileDetails.socialMedia.twitter"
                              :href="profileDetails.socialMedia.twitter"
                              target="_blank"
                              ><i class="fa-brands fa-instagram"></i
                            ></a>
                          </li>
                          <li v-if="profileDetails.socialMedia.linkedIn">
                            <a
                              v-if="profileDetails.socialMedia.linkedIn"
                              :href="profileDetails.socialMedia.linkedIn"
                              target="_blank"
                              ><i class="fa-brands fa-linkedin"></i
                            ></a>
                          </li>
                          <li v-if="profileDetails.socialMedia.otherURLS">
                            <a
                              v-if="profileDetails.socialMedia.otherURLS"
                              :href="profileDetails.socialMedia.otherURLS"
                              target="_blank"
                              ><i class="fa-solid fa-globe"></i
                            ></a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div v-if="images.length > 0">
                    <hr />
                    <!-- component -->
                    <viewer :images="images" class="mx-2 my-1 viewerImg">
                      <img
                        v-for="src in images"
                        :key="src"
                        :src="src"
                        width="70px"
                        class="p02"
                      />
                    </viewer>
                  </div>

                  <!-- Table details-->
                  <div
                    class="d-flex align-items-center justify-content-center mb-2 mb-md-0 mt-1"
                  >
                    <b-table-simple responsive class="profileTable">
                      <b-tr>
                        <b-td
                          ><b>{{ $t("profileDetails.Profile_belongs_to") }}</b>
                        </b-td>
                        <b-td class="text-details-value">{{
                          $store.state.locale.locale == "ar"
                            ? profileDetails.typeofOwner == "individual"
                              ? "فرد"
                              : "مجموعة"
                            : profileDetails.typeofOwner
                        }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td
                          ><b>{{ $t("profileDetails.category") }} </b>
                        </b-td>
                        <b-td class="text-details-value">{{
                          $store.state.locale.locale == "ar"
                            ? profileDetails.category.arabicName
                            : profileDetails.category.name
                        }}</b-td>
                      </b-tr>
                      <b-tr v-if="profileDetails.saleType">
                        <b-td
                          ><b>{{ $t("profileDetails.Sell_by") }}</b>
                        </b-td>
                        <b-td
                          v-if="profileDetails.saleType == 1"
                          class="text-details-value"
                          >{{ $t("profileDetails.My_Own_Price") }}</b-td
                        >
                        <b-td
                          v-if="profileDetails.saleType == 2"
                          class="text-details-value"
                          >{{ $t("profileDetails.Auction") }}</b-td
                        >
                      </b-tr>
                      <b-tr
                        v-if="
                          profileDetails.saleType == 1 && profileDetails.price
                        "
                      >
                        <b-td
                          ><b>{{ $t("profileDetails.Price") }}</b>
                        </b-td>
                        <b-td class="text-details-value"
                          >{{
                            $store.state.locale.locale == "en"
                              ? calcPrice(profileDetails.price.priceNumber)
                              : arCalcPrice(profileDetails.price.priceNumber)
                          }}
                          {{
                            $store.state.locale.locale == "ar"
                              ? profileDetails.price.currencyId.arabicName
                              : profileDetails.price.currencyId.name
                          }}
                          <span>
                            {{
                              $store.state.locale.locale == "en"
                                ? " ≃ " +
                                  calcPrice(
                                    currencyExchange(
                                      profileDetails.price.priceNumber,
                                      profileDetails.price.currencyId.symbol
                                    )
                                  )
                                : " ≃ " +
                                  arCalcPrice(
                                    currencyExchange(
                                      profileDetails.price.priceNumber,
                                      profileDetails.price.currencyId.symbol
                                    )
                                  )
                            }}</span
                          ><span>{{
                            $store.state.myCurrency.myCurrency.symbol
                          }}</span>
                        </b-td>
                      </b-tr>

                      <!-- Service-->
                      <b-tr v-if="profileDetails.profileType == 'service'">
                        <b-td
                          ><b>{{ $t("profileDetails.Duration_of_service") }}</b>
                        </b-td>
                        <b-td class="text-details-value">{{
                          returnServiceType(profileDetails.service.serviceType)
                        }}</b-td>
                      </b-tr>
                      <b-tr v-if="profileDetails.profileType == 'service'">
                        <b-td
                          ><b>{{ $t("profileDetails.Service_type") }}</b>
                        </b-td>
                        <b-td class="text-details-value">{{
                          returnServiceLocation(
                            profileDetails.service.serviceLocation
                          )
                        }}</b-td>
                      </b-tr>
                      <!--end  Service-->

                      <!--  project -->
                      <b-tr v-if="profileDetails.profileType == 'project'">
                        <b-td
                          ><b>{{ $t("profileDetails.Staff_Number") }}</b>
                        </b-td>
                        <b-td class="text-details-value">{{
                          profileDetails.project.staffNumbers
                        }}</b-td>
                      </b-tr>
                      <b-tr v-if="profileDetails.profileType == 'project'">
                        <b-td
                          ><b>{{ $t("profileDetails.project_Time") }}</b>
                        </b-td>
                        <b-td
                          class="text-details-value"
                          v-if="profileDetails.project.projectTime == 1"
                        >
                          {{ $t("profileDetails.Open") }}
                        </b-td>
                        <b-td
                          class="text-details-value"
                          v-if="profileDetails.project.projectTime == 2"
                        >
                          {{ $t("profileDetails.Scheduled") }}</b-td
                        >
                      </b-tr>
                      <b-tr v-if="profileDetails.profileType == 'project'">
                        <b-td
                          ><b>{{ $t("profileDetails.Start_at") }}</b>
                        </b-td>
                        <b-td class="text-details-value">
                          {{
                            format_Date(profileDetails.project.startAt)
                          }}</b-td
                        >
                      </b-tr>
                      <b-tr
                        v-if="
                          profileDetails.profileType == 'project' &&
                          profileDetails.project.endAt
                        "
                      >
                        <b-td
                          ><b>{{ $t("profileDetails.End_at") }}</b>
                        </b-td>
                        <b-td class="text-details-value">
                          {{ format_Date(profileDetails.project.endAt) }}</b-td
                        >
                      </b-tr>
                      <b-tr v-if="profileDetails.profileType == 'project'">
                        <b-td
                          ><b>{{ $t("profileDetails.Budget") }}</b>
                        </b-td>
                        <b-td class="text-details-value">
                          {{ profileDetails.project.budgetAmount }}
                          <span>{{
                            profileDetails.project.budgetCurrencyId.name
                          }}</span>
                        </b-td>
                      </b-tr>
                      <b-tr v-if="profileDetails.profileType == 'project'">
                        <b-td
                          ><b>{{ $t("profileDetails.Assets") }}</b>
                        </b-td>
                        <b-td class="text-details-value">
                          {{ profileDetails.project.assetsType }}
                        </b-td>
                      </b-tr>
                      <!-- end project-->

                      <!-- Product-->
                      <b-tr v-if="profileDetails.profileType == 'product'">
                        <b-td
                          ><b>{{ $t("profileDetails.Quantity") }}</b>
                        </b-td>
                        <b-td class="text-details-value">{{
                          profileDetails.product.productQuantity
                        }}</b-td>
                      </b-tr>
                      <b-tr v-if="profileDetails.profileType == 'product'">
                        <b-td
                          ><b>{{ $t("profileDetails.Product_sales") }}</b>
                        </b-td>
                        <b-td class="text-details-value">{{
                          profileDetails.product.productSales
                        }}</b-td>
                      </b-tr>
                      <b-tr
                        v-if="
                          profileDetails.profileType == 'product' &&
                          profileDetails.product.productRegistration
                        "
                      >
                        <b-td
                          ><b>{{
                            $t("profileDetails.Product_registration")
                          }}</b>
                        </b-td>
                        <b-td class="text-details-value">{{
                          profileDetails.product.productRegistration
                        }}</b-td>
                      </b-tr>
                      <b-tr
                        v-if="
                          profileDetails.profileType == 'product' &&
                          profileDetails.product.productExpiryDate
                        "
                      >
                        <b-td
                          ><b>{{ $t("profileDetails.Expiry_Date") }} </b>
                        </b-td>
                        <b-td class="text-details-value">{{
                          format_Date(profileDetails.product.productExpiryDate)
                        }}</b-td>
                      </b-tr>
                      <!--end Product-->

                      <!-- Idea -->

                      <b-tr v-if="profileDetails.profileType == 'idea'">
                        <b-td
                          ><b>{{ $t("profileDetails.Status") }}</b>
                        </b-td>
                        <b-td
                          class="text-details-value"
                          v-if="profileDetails.idea.ideaStatus == 1"
                          >{{ $t("profileDetails.Complete") }}</b-td
                        >
                        <b-td
                          class="text-details-value"
                          v-if="profileDetails.idea.ideaStatus == 2"
                          >{{ $t("profileDetails.Incomplete") }}</b-td
                        >
                      </b-tr>
                      <!-- end Idea -->

                      <b-tr>
                        <b-td
                          ><b>{{ $t("profileDetails.Industry") }}</b>
                        </b-td>
                        <b-td class="text-details-value">
                          <a
                            v-for="(
                              data, index
                            ) in profileDetails.profileIndustries"
                            :key="index"
                            class="text-primary"
                            target="_self"
                          >
                            <span class="" v-if="data.name">{{
                              $store.state.locale.locale == "ar"
                                ? "#" + data.arabicName + " "
                                : "#" + data.name + " "
                            }}</span>
                          </a>
                        </b-td>
                      </b-tr>
                    </b-table-simple>
                  </div>
                  <!-- Highlights &  Description & details of Owner / -->
                  <hr />
                  <div class="ml-2 pb-2 btabs">
                    <b-tabs>
                      <b-tab active>
                        <template #title>
                          <feather-icon icon="StarIcon" />
                          <span>{{ $t("profileDetails.Brief_overview") }}</span>
                        </template>
                        <b-card-text class="text-details-desc">{{
                          profileDetails.highlights
                        }}</b-card-text>
                      </b-tab>
                      <b-tab>
                        <template #title>
                          <feather-icon icon="UserIcon" />
                          <span>{{
                            $t("profileDetails.Details_of_Owner")
                          }}</span>
                        </template>
                        <b-card-text class="text-details-desc">{{
                          profileDetails.detailsofOwner
                        }}</b-card-text>
                      </b-tab>
                      <b-tab v-if="profileDetails.profileType == 'idea'">
                        <template #title>
                          <feather-icon icon="BookOpenIcon" />
                          <span>{{ $t("profileDetails.Reference") }}</span>
                        </template>
                        <b-card-text class="text-details-desc">{{
                          profileDetails.idea.ideaReference
                        }}</b-card-text>
                      </b-tab>
                      <b-tab>
                        <template #title>
                          <feather-icon icon="TagIcon" />
                          <span>{{ $t("profileDetails.Description") }}</span>
                        </template>
                        <b-card-text class="text-details-desc">{{
                          profileDetails.description
                        }}</b-card-text>
                      </b-tab>
                    </b-tabs>
                    <!-- Product Description -->
                    <!-- <div class="highlights">
                      <h4>Highlights:</h4>
                      <b-card-text class="text-details-desc">{{
                        profileDetails.highlights
                      }}</b-card-text>
                    </div>
                    <hr />
                    <div class="detailsofOwner">
                      <h4>Details of Owner:</h4>
                      <b-card-text class="text-details-desc">{{
                        profileDetails.detailsofOwner
                      }}</b-card-text>
                    </div> -->

                    <!-- for idea profile-->
                    <!-- <div
                      class="Reference"
                      v-if="profileDetails.profileType == 'idea'"
                    >
                      <hr />
                      <h4>Reference:</h4>
                      <b-card-text class="text-details-desc">{{
                        profileDetails.idea.ideaReference
                      }}</b-card-text>
                    </div> -->
                    <!-- end for idea profile-->

                    <!-- <hr />
                    <div class="description">
                      <h4>Description:</h4>
                      <b-card-text class="text-details-desc">{{
                        profileDetails.description
                      }}</b-card-text>
                    </div> -->
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
            <!-- <silent-box :gallery="imagesFormated"></silent-box> -->

            <b-col md="5">
              <div v-if="profileDetails.saleType == 2">
                <b-card class="card-top-1">
                  <b-card-body>
                    <b-row class="auction-startBtn"> </b-row>
                    <div v-if="profileDetails.saleType != '1'">
                      <div v-if="profileDetails.auctionId != null">
                        <div
                          v-if="
                            profileDetails.auctionId.endDate != null &&
                            profileDetails.auctionId.status == 'created'
                          "
                        >
                          <b-row class="rowDeadline">
                            <flip-countdown
                              class="countdown2"
                              :deadline="deadline"
                              :labels="
                                $store.state.locale.locale == 'ar'
                                  ? labelsArabic
                                  : labels
                              "
                              @timeElapsed="timeElapsedHandler"
                            ></flip-countdown>
                            <div v-if="$store.getters['auth/isAuth']">
                              <div
                                v-if="
                                  profileDetails.userId._id == user._id &&
                                  profileDetails.saleType != '1'
                                "
                              >
                                <span
                                  v-if="
                                    profileDetails.auctionId.status == 'created'
                                  "
                                >
                                  <b-button
                                    class="stopAuctionBtn ml-1 mt-2"
                                    variant="primary"
                                    @click="stopActionFunc"
                                  >
                                    <span class="text-nowrap"
                                      >{{ $t("profileDetails.Stop_Auction") }}
                                    </span>
                                  </b-button>
                                </span>
                              </div>
                            </div>
                          </b-row>
                        </div>
                        <div
                          v-if="
                            AuctionType == 'open' &&
                            profileDetails.auctionId.status != 'closed'
                          "
                        >
                          <div>
                            <h2 class="text-center auctionStartText">
                              {{ $t("profileDetails.Auction_started_at") }}
                              {{
                                moment(
                                  profileDetails.auctionId.startDate
                                ).fromNow(false)
                              }}
                            </h2>
                            <div
                              class="d-flex flex-wrap justify-content-center"
                            >
                              <b-spinner
                                v-for="variant in variants"
                                :key="variant"
                                :variant="variant"
                                class="mr-1"
                                type="grow"
                              />
                            </div>
                          </div>
                          <div v-if="$store.getters['auth/isAuth']">
                            <div
                              v-if="
                                profileDetails.userId._id == user._id &&
                                profileDetails.saleType != '1'
                              "
                              class="text-center"
                            >
                              <span
                                v-if="
                                  profileDetails.auctionId.status == 'created'
                                "
                              >
                                <b-button
                                  class="stopAuctionBtn ml-1 mt-2"
                                  variant="primary"
                                  @click="stopActionFunc"
                                >
                                  <span class="text-nowrap">
                                    {{
                                      $t("profileDetails.Stop_Auction")
                                    }}</span
                                  >
                                </b-button>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div v-if="profileDetails.auctionId.status == 'closed'">
                          <h2 class="text-center auctionStartText">
                            {{ $t("profileDetails.Auction_is_over") }}
                          </h2>
                        </div>
                      </div>
                      <div v-else>
                        <div
                          class="startAuctionDiv d-flex justify-content-center"
                        >
                          <h3 class="text-center auctionStartText">
                            {{ $t("profileDetails.Auction_not_started") }}
                          </h3>
                          <div class="item-wrapper">
                            <div v-if="$store.getters['auth/isAuth']">
                              <div
                                v-if="
                                  profileDetails.userId._id == user._id &&
                                  profileDetails.saleType != '1'
                                "
                              >
                                <div v-if="profileDetails.auctionId == null">
                                  <add-auction
                                    :is-add-new-user-sidebar-active.sync="
                                      isAddNewUserSidebarActive
                                    "
                                    :profileId="profileDetails._id"
                                  />
                                  <b-button
                                    class="startAuctionBtn ml-1"
                                    variant="primary"
                                    @click="isAddNewUserSidebarActive = true"
                                  >
                                    <span class="text-nowrap"
                                      >{{ $t("profileDetails.Start_Auction") }}
                                    </span>
                                  </b-button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-card-body>
                </b-card>
                <b-card
                  v-if="
                    profileDetails.saleType != '1' &&
                    profileDetails.auctionId != null
                  "
                >
                  <b-card-body>
                    <b-row>
                      <b-col md="12">
                        <!-- <h3 class="text-center mt-1">
                      Initial price :{{
                        profileDetails.auctionId.initialPrice +
                        " " +
                        profileDetails.auctionId.currencyId.name
                      }}
                    </h3> -->
                        <div>
                          <bids
                            :auctionId="profileDetails.auctionId"
                            :AuctionStatus="AuctionStatus"
                          />
                        </div>
                      </b-col>
                    </b-row>
                  </b-card-body>
                </b-card>
              </div>
              <!-- <div v-if="profileDetails.saleType == 1">
              <b-card class="card-top-1">
                <b-card-body>
                  <h3 v-if="profileDetails.price">
                    {{ profileDetails.price.priceNumber }}
                    {{ profileDetails.price.currencyId.name }}
                  </h3>
                </b-card-body>
              </b-card>
            </div> -->
              <Comments
                :data="profileDetails"
                :totalComments="profileDetails.totalComments"
                :profileId="profileDetails._id"
              />
            </b-col>
          </b-row>
          <!-- <b-row class="mb-3">
            <b-col md="12">
             
              <swiper
                class="swiper-coverflow swiperImg"
                :options="swiperOptions"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              >
                <swiper-slide v-for="(data, index) in images" :key="index">
                 
                  <div class="images">
                    <viewer :options="optionsDef" class="viewer" ref="viewer">
                      <b-img :src="data" fluid />
                    </viewer>
                  </div>
                </swiper-slide>
                <div slot="pagination" class="swiper-pagination" />
              </swiper>
            </b-col>
          </b-row> -->
        </b-container>
      </section>
      <Footer class="footer-companyList" />
    </div>
    <!-- <div v-else>
      <b-container>
        <b-alert variant="danger" class="mt-9" show>
          <h4 class="alert-heading">
            {{ $t("profileDetails.Error_fetching_data") }}
          </h4>
          <div class="alert-body">
            {{ $t("profileDetails.Back_to") }}
            <b-link
              class="alert-link"
              :to="{
                name: 'timeline',
              }"
            >
              {{ $t("profileDetails.Business_Lounge") }}
            </b-link>
          </div>
        </b-alert>
      </b-container>
    </div> -->
  </div>
</template>

<script>
import moment from "moment";

import FlipCountdown from "vue2-flip-countdown";

import axios from "axios";
import Comments from "./Comments";

import { mapActions, mapGetters } from "vuex";

import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
import Header from "@/layouts/landpage/Header.vue";
import Footer from "@/layouts/landpage/Footer.vue";
import { useRouter } from "@core/utils/utils";
import store from "@/store";
import { ref } from "@vue/composition-api";
import AddAuction from "./AddAuction.vue";
import Bids from "./Bids";
import millify from "millify";

import counter from "@/views/components/Counter/Counter.vue";

import SkeletonDetailsProfile from "../../../../views/nftdubai/components/SkeltonDetailsProfile.vue";

import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BImg,
  BCardText,
  BLink,
  BButton,
  BDropdown,
  BDropdownItem,
  BAlert,
  BTableSimple,
  BTr,
  BTd,
  BContainer,
  BSpinner,
  BTabs,
  BTab,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
// import CompanyDetailsFeatures from "./CompanyDetailsFeatures.vue";
// import CompanyDetailsRelated from "./CompanyDetailsRelated.vue";
// import companyImagesSlider from "./companyImagesSlider.vue";
// import Vue from "vue";

// import VueSilentbox from "vue-silentbox";
// Vue.use(VueSilentbox);

export default {
  data() {
    return {
      errorMessage: "",
      loading: false,
      labels: {
        days: "days",
        hours: "hours",
        minutes: "minutes",
        seconds: "seconds",
      },
      labelsArabic: {
        days: "يوم",
        hours: "ساعة",
        minutes: "دقيقة",
        seconds: "ثانية",
      },
      optionsDef: { zIndex: 100002 },
      AuctionOver: false,
      AuctionStatus: "",
      AuctionType: "",
      stopAction: true,
      deadline: null,
      icon: null,
      images: [],
      imagesFormated: [],
      index: null,

      isSold: false,
      profileDetails: {
        name: "",
        typeofOwner: "",
        description: "",
        highlights: "",
        detailsofOwner: "",
        userId: {
          username: "",
        },
        address: {
          profileCity: "",
          profileState: "",
          profileCountry: {
            name: "",
          },
        },
        socialMedia: {
          facebook: "",
          linkedIn: "",
          twitter: "",
          otherURLS: "",
        },
        blockchainExchange: "",
        price: {
          priceNumber: "",
          currencyId: {
            name: "",
          },
        },
        category: {
          name: "",
        },
        saleType: "",
        profileIndustries: [],
        rating: "4",
        totalComments: "",
        comments: [],
        service: {
          serviceType: "",
          serviceLocation: "",
        },
        project: {
          staffNumbers: "",
          startAt: "",
          endAt: "",
          budgetAmount: "",
          budgetCurrencyId: {
            name: "",
          },
        },
        product: {
          productQuantity: "",
          productSales: "",
          productRegistration: "",
          productExpiryDate: "",
        },
        idea: {
          ideaReference: "",
          ideaStatus: "",
        },
        auctionId: {
          bids: [],
          currencyId: {
            name: "",
          },
        },
      },
      deadlineFormat: {
        year: null,
        mounth: null,
      },
      rating: "4",
      // product: {
      //   id: 1,
      //   image: require("../../../../assets/images/pages/companylogo.webp"),
      //   name: "Nutri Booster Healthy Foods",
      //   typeofOwner: "Group",
      //   category: "Full Sale",
      //   saleType: "My own price",
      //   priceNumber: "250",
      //   currencyId: "$",
      //   serviceType: "Contract",
      //   serviceLocation: "Online",
      //   userName: "nft company",

      //   twitter: "//twitter.com",
      //   facebook: "//fb.com",
      //   linkedin: "//linked.com",
      //   otherUrls: "//otherUrl.com",

      //   location: "United Arab Emirates-Duabi",
      //   description:
      //     " Describe the business  Describe the business  Describe the business  Describe the business   Describe the business  Describe the business  Describe the business  Describe the business ",
      //   assets:
      //     "tangible and intangible assets the tangible and intangible assets the tangible and intangible assets the ",

      //   highlights:
      //     " Highlights Highlights Highlights  Highlights Highlights Highlights  Highlights Highlights Highlights ",
      //   EBITDA: "70",
      //   industry: "industrial industrial industrial",

      //   country: "UAE",
      //   state: "Dubai",
      // },

      swiperData: [
        {
          src: require("@/assets/images/banner/banner-35.jpg"),
          thumbnailHeight: "50px",
        },
        {
          src: require("@/assets/images/banner/banner-39.jpg"),
          thumbnailHeight: "50px",
        },
        {
          src: require("@/assets/images/banner/banner-38.jpg"),
          thumbnailHeight: "50px",
        },
        // { src: require("@/assets/images/banner/banner-37.jpg") },
        // { src: require("@/assets/images/banner/banner-36.jpg") },
        // { src: require("@/assets/images/banner/banner-34.jpg") },
        // { src: require("@/assets/images/banner/banner-33.jpg") },
        // { src: require("@/assets/images/banner/banner-32.jpg") },
        // { src: require("@/assets/images/banner/banner-31.jpg") },
      ],

      swiperOptions: {
        effect: "coverflow",
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: "auto",
        coverflowEffect: {
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        },
        pagination: {
          el: ".swiper-pagination",
        },
      },
      variants: ["primary", "secondary", "warning", "success", "dark"],
    };
  },

  directives: {
    Ripple,
  },
  components: {
    Header,
    Footer,
    Comments,
    AddAuction,
    Bids,
    // BSV
    BCard,
    BCardBody,
    BRow,
    BCol,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
    BTableSimple,
    BTr,
    BTd,
    VueViewer,
    BContainer,
    Swiper,
    SwiperSlide,
    BImg,
    counter,
    FlipCountdown,
    // SFC
    // CompanyDetailsFeatures,
    // CompanyDetailsRelated,
    // companyImagesSlider,
    BSpinner,
    BTabs,
    BTab,
    SkeletonDetailsProfile,
    // VueSilentbox,
  },

  setup() {
    const isAddNewUserSidebarActive = ref(false);
    return {
      isAddNewUserSidebarActive,
    };
  },
  created() {
    //$themeConfig.layout.menu.hidden = true;
    //console.log(store.state.appConfig.layout.menu.hidden);
    //console.log(store.state.appConfig.layout.footerType);
    store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", true);
    store.commit("appConfig/UPDATE_FOOTER_CONFIG", { type: "hidden" });
    store.commit("appConfig/UPDATE_NAVBAR_CONFIG", { type: "hidden" });
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  methods: {
    format_Date(value) {
      return moment(String(value)).format("YYYY-MM-DD");
    },
    ...mapActions({
      editAuctionStatus: "profile/editAuctionStatus",
      fetchCurrencies: "currency/retreiveCurrencies",
    }),
    moment: function (input) {
      return moment(input).locale(this.$store.state.locale.locale);
    },
    filterIcon(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].name == "Profile Icon") {
          this.icon = data[i].fileUrl;
        }
      }
    },
    filterImages(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].name == "Profile Images") {
          // this.images = data[i].fileUrl;
          this.images.push(data[i].fileUrl);
        }
      }
    },

    timeElapsedHandler() {
      //  timeElapsedHandler: async function () {
      this.AuctionOver = true;
      //changeStatus
      const formData = new FormData();
      formData.append("status", "closed");

      const dataOb = {};
      dataOb.auctionId = this.profileDetails.auctionId._id;
      dataOb.formData = formData;

      //axios.put(`changeStatus/${this.profileDetails.auctionId._id}`, formData);
      this.editAuctionStatus(dataOb);
      window.location.reload(true);

      //location.reload();
    },
    stopActionFunc() {
      this.$bvModal
        .msgBoxConfirm(this.$t("profileDetails.Are_sure_stop_auction"), {
          title: this.$t("profileDetails.Please_Confirm"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          okTitle: this.$t("messages.YES"),
          cancelTitle: this.$t("messages.NO"),
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            const formData = new FormData();
            formData.append("status", "closed");

            const dataOb = {};
            dataOb.auctionId = this.profileDetails.auctionId._id;
            dataOb.formData = formData;

            //axios.put(`changeStatus/${this.profileDetails.auctionId._id}`, formData);
            this.editAuctionStatus(dataOb);
            window.location.reload(true);
          }
        });
    },
    checkEndDate() {
      //  timeElapsedHandler: async function () {
      this.AuctionOver = true;
      //changeStatus
      const formData = new FormData();
      formData.append("status", "closed");

      const dataOb = {};
      dataOb.auctionId = this.profileDetails.auctionId._id;
      dataOb.formData = formData;

      //axios.put(`changeStatus/${this.profileDetails.auctionId._id}`, formData);
      this.editAuctionStatus(dataOb);
      // window.location.reload(true);

      //location.reload();
    },

    formatDate(data) {
      const current = new Date(data);

      const year = current.getFullYear();
      const month = current.getMonth() + 1;
      const days = current.getDate();
      const hours = current.getHours();
      const minutes = current.getMinutes();
      const seconds = current.getSeconds();

      const deadlineformat =
        year +
        "-" +
        month +
        "-" +
        days +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds;
      // console.log(deadlineformat);
      return deadlineformat;
    },
    returnServiceType(data) {
      let serviceType = "";
      switch (data) {
        case "contract":
          serviceType =
            localStorage.getItem("language") === "ar" ? "عقد" : "Contract";
        case "hourly":
          serviceType =
            localStorage.getItem("language") === "ar" ? "بالساعة" : "Hourly";
        case "daily":
          serviceType =
            localStorage.getItem("language") === "ar" ? "يومياً" : "Daily";
        case "weekly":
          serviceType =
            localStorage.getItem("language") === "ar" ? "أسبوعياً" : "Weekly";
        case "monthly":
          serviceType =
            localStorage.getItem("language") === "ar" ? "شهرياً" : "Monthly";
        case "yearly":
          serviceType =
            localStorage.getItem("language") === "ar" ? "سنوياً" : "Yearly";
        default:
          serviceType =
            localStorage.getItem("language") === "ar" ? "عقد" : "Contract";
      }
      return serviceType;
    },
    returnServiceLocation(data) {
      let ServiceLocation = "";
      switch (data) {
        case "online":
          ServiceLocation =
            localStorage.getItem("language") === "ar" ? "أون لاين" : "Online";
        case "offline":
          ServiceLocation =
            localStorage.getItem("language") === "ar" ? "أوف لاين" : "Offline";
      }
      return ServiceLocation;
    },
    calcPrice(number) {
      return `${millify(number, {
        precision: 1,
        decimalSeparator: ".",
        space: true,
      })} `;
    },
    arCalcPrice(number) {
      return `${millify(number, {
        precision: 1,
        units: ["", "الف", "مليون", "مليار", "بليون"],
        decimalSeparator: ".",
        space: true,
      })} `;
    },

    formatFiles(data) {
      data.forEach((element) => {
        this.imagesFormated.push({ src: element.fileUrl });
      });
    },
  },
  mounted() {
    this.fetchCurrencies();

    //    ...mapGetters({
    //   //   user: "auth/user",
    //   profileDetails: "profile/getServiceDetails",
    // }),
    //this.retreiveProfileDetails(this.$route.params.id);

    axios
      .get(`profiles/${this.$route.params.id}`)
      .then((response) => {
        this.profileDetails = response.data.data;

        this.isSold = this.profileDetails.isSold;
        // console.log(typeof this.profileDetails.address.profileState);

        try {
          if (this.profileDetails.address.profileState) {
            this.profileDetails.address.profileState = JSON.parse(
              this.profileDetails.address.profileState
            );

            this.profileDetails.address.profileState =
              this.$store.state.locale.locale == "ar"
                ? this.profileDetails.address.profileState.arabicName !=
                  undefined
                  ? this.profileDetails.address.profileState.arabicName
                  : this.profileDetails.address.profileState.name
                : this.profileDetails.address.profileState.name;
          }
        } catch (error) {}

        // if (this.profileDetails.profileType == "service") {
        //   this.profileDetails.service.serviceType =
        //     this.profileDetails.service.serviceType;
        //   this.profileDetails.service.serviceLocation =
        //     this.profileDetails.service.serviceLocation;
        // }
        this.filterIcon(this.profileDetails.files);
        this.filterImages(this.profileDetails.files);
        // this.formatFiles(this.profileDetails.files);
        if (
          this.profileDetails.saleType != "1" &&
          this.profileDetails.auctionId != null
        ) {
          this.AuctionStatus = this.profileDetails.auctionId.status;
          this.AuctionType = this.profileDetails.auctionId.type;
          const todaysDate = new Date();

          this.deadline = this.formatDate(
            this.profileDetails.auctionId.endDate
          );

          if (this.AuctionStatus != "closed") {
            var g1 = new Date(this.profileDetails.auctionId.endDate);
            var g2 = new Date();
            if (g1.getTime() < g2.getTime()) this.checkEndDate();
          }
        }
        //profileDetails.files[0].fileUrl
      })
      .then(() => {
        this.loading = true;
      })
      .catch((error) => {
        // console.log(error);
        this.errorMessage = error.response.data.error.message;
        // this.loading = true;
      });
    // this.userId = this.user._id;

    // this.loading = true;
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
</style>
<style>
.text-black {
  color: #000;
}
.text-w500 {
  font-weight: 500;
}
.ml05 {
  margin-left: 5px;
}
@media (max-width: 550px) {
  .btabs ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
.p02 {
  padding: 0.2rem;
}
</style>
<style scoped>
.viewerImg {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.viewerImg::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.viewerImg {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

[dir] .viewer-backdrop {
  z-index: 100005;
}
.location-social {
  display: flex;
}

.card-details {
  margin-top: 100px;
}

.contact-social ul {
  display: flex;
  flex-wrap: nowrap;
  align-content: flex-end;
  justify-content: flex-start;
  list-style: none;
  margin-left: -42px;
}
.contact-social ul li {
  margin: 12px 12px 12px 0;
}
.contact-social ul li .fa-brands {
  font-size: 25px;
  color: #366ce3;
}
.contact-social ul li .fa-globe {
  font-size: 25px;
  color: #366ce3;
}
.contact-social ul li .fa-brands:hover {
  color: #166fb6;
}
.contact-social ul li .fa-globe:hover {
  color: #166fb6;
}

.swiperImg img {
  width: 250px;
  height: 250px;
}
[dir="ltr"] .row {
  width: 100%;
}
.item-wrapper {
  display: flex;
  flex-wrap: nowrap;
}
.reviews-text {
  color: #999;
  font-weight: 500;
  margin-left: 7px;
}
.details-img-name {
  display: flex;
}
.details-img-name img {
  border-radius: 15px;
}
.details-img-name .name-location {
  margin-left: 1rem;
}

@media (max-width: 550px) {
  .details-img-name {
    display: flex;
    flex-direction: row;
    margin-bottom: -2rem;
  }
  .details-img-name img {
    width: 75px;
    height: 75px;
  }
  .details-img-name .name-location {
    /* margin-left: 0; */
    margin-left: 1rem;
  }
  .details-img-name .name-location h3 {
    font-size: 1.286rem;
  }
  table tr td b {
    font-size: 12px;
  }
}

.rating-row {
  margin: auto;
}
</style>
<style scoped>
.swiper-coverflow.swiper-container .swiper-slide {
  width: 300px;
}
table {
  margin-top: 12px;
}
.profileTable tr td:first-child {
  width: 25%;
}
</style>
<style>
.rowDeadline {
  width: auto;
}
span.flip-clock__card.flip-card {
  font-size: 2.4rem;
  color: #fff;
}
b.flip-card__top,
b.flip-card__bottom {
  color: #fff;
  background: #366ce3;
}
b.flip-card__back::before {
  background: #366ce3;
  color: #fff;
}
b.flip-card__back-bottom {
  background: #366ce3;
  color: #fff;
}

b.flip-card__top,
b.flip-card__bottom {
  color: #fff;
}

[dir] b.flip-card__top,
[dir] b.flip-card__bottom {
  background: #366ce3;
  color: #fff;
}
[dir] b.flip-card__back::before {
  color: #fff;
}
[dir] b.flip-card__back-bottom {
  color: #fff;
}
[dir] span.flip-clock__slot {
  font-size: 1rem;
}

.auction-startBtn {
  display: flex;
  flex-direction: row-reverse;
}
</style>

<style scoped>
.startAuctionBtn {
  padding: 7px;
  font-size: 12px;
}
.stopAuctionBtn {
  padding: 7px;
  font-size: 12px;
}
.auctionStartText {
  color: #6d6d9f;
}

.card-top-1 {
  /* background-color: #dceef0; */
  background-color: #e0e9ea;
  border-radius: 1rem;
}

.text-details-value {
  color: #6d6d9f;
  font-weight: 500;
}
.text-details-desc {
  color: #645e95;
  font-weight: 500;
}
.ownedName {
  font-weight: 500;
  color: #366ce3;
}
@media (max-width: 550px) {
  .auctionStartText {
    font-size: 17px;
  }
}

.rowDeadline {
  display: flex;
  justify-content: center;
}
</style>

<!-- Style for ribbon add is Sold  -->
<style scoped>
.box {
  /* position: relative;
  max-width: 600px;
  width: 90%;
  height: 400px; */
  background: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

/* common */
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  /* border: 5px solid #2980b9; */
  border: 5px solid #2980b9;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: #127fbd;
  /* background-color: #3498db; */
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 700 18px/1 "Lato", sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
}

/* top left*/
.ribbon-top-left {
  top: -10px;
  left: -10px;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-top-left::before {
  top: 0;
  right: 0;
}
.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}
.ribbon-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}

/* top right*/
.ribbon-top-right {
  top: -10px;
  right: -10px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-top-right::before {
  top: 0;
  left: 0;
}
.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}
.ribbon-top-right span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
}

/* bottom left
.ribbon-bottom-left {
  bottom: -10px;
  left: -10px;
}
.ribbon-bottom-left::before,
.ribbon-bottom-left::after {
  border-bottom-color: transparent;
  border-left-color: transparent;
}
.ribbon-bottom-left::before {
  bottom: 0;
  right: 0;
}
.ribbon-bottom-left::after {
  top: 0;
  left: 0;
}
.ribbon-bottom-left span {
  right: -25px;
  bottom: 30px;
  transform: rotate(225deg);
} */

/* bottom right*/
/* .ribbon-bottom-right {
  bottom: -10px;
  right: -10px;
}
.ribbon-bottom-right::before,
.ribbon-bottom-right::after {
  border-bottom-color: transparent;
  border-right-color: transparent;
}
.ribbon-bottom-right::before {
  bottom: 0;
  left: 0;
}
.ribbon-bottom-right::after {
  top: 0;
  right: 0;
}
.ribbon-bottom-right span {
  left: -25px;
  bottom: 30px;
  transform: rotate(-225deg);
} */

@media (max-width: 550px) {
  .ribbon {
    width: 123px;
    height: 123px;
    overflow: hidden;
    position: absolute;
  }
  .ribbon span {
    padding: 7px 7px 7px 0;
    background-color: #127fbd;
    box-shadow: 0 5px 10px rgb(0 0 0 / 10%);
    text-shadow: 0 1px 1px rgb(0 0 0 / 20%);
    text-align: center;
  }

  .ribbon-top-right::after {
    bottom: 19px;
  }
  .ribbon-top-right::before {
    left: 19px;
  }
}
</style>
