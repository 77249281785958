<template>
  <div class="mx-auto">
    <b-card>
      <!-- likes comments  share-->
      <b-row class="pb-50 mt-50 border-bottom pb-1 mb-2">
        <b-col
          sm="8"
          class="d-flex justify-content-between justify-content-sm-start mb-2"
        >
          <b-link class="d-flex align-items-center text-nowrap">
            <span class="text-muted" v-if="this.data.totalComments == 0">
              {{ $t("profileDetails.Be_first_comment") }}</span
            >
            <span class="text-primary" v-else @click="showAllCommentsFun">
              {{ $t("profileDetails.All_Comments") }}</span
            >
          </b-link>
        </b-col>
        <b-col
          sm="4"
          class="d-flex justify-content-between justify-content-sm-end align-items-center mb-2"
        >
          <b-link
            :class="{
              'text-nowrap': true,
              'text-muted': data.totalComments < 1,
              'text-primary': data.totalComments,
            }"
          >
            <feather-icon
              icon="MessageSquareIcon"
              size="18"
              class="profile-icon mr-50"
            />
            <span class="mr-1">{{ kFormatter(this.data.totalComments) }}</span>
          </b-link>
        </b-col>
      </b-row>
      <!--/ likes comments  share-->

      <!-- comments -->
      <div v-if="showAllComments" class="allComments">
        <div
          v-for="(comment, ind) in data.comments"
          :key="ind"
          class="d-flex align-items-start mb-1"
        >
          <b-link :to="`/profile/${comment.userId.username}`"
            ><b-avatar
              :src="comment.userId.profileImage"
              size="34"
              class="mt-25 mr-75"
          /></b-link>
          <div class="profile-user-info w-100">
            <div class="d-flex align-items-center justify-content-between">
              <h6 class="mb-0">
                <b-link :to="`/profile/${comment.userId.username}`">{{
                  comment.userId.username
                }}</b-link>
                ⸱
                <span class="text-muted">{{
                  moment(comment.createdAt).fromNow(false)
                }}</span>
              </h6>

              <div class="text-body">
                <feather-icon
                  v-if="
                    $store.getters['auth/isAuth'] &&
                    $store.getters['auth/user']._id == comment.userId._id
                  "
                  icon="TrashIcon"
                  size="18"
                  class="mr-25"
                  :class="comment.youLiked ? 'profile-likes' : 'profile-icon'"
                  @click="deleteComment(comment._id)"
                />
                <!--  <span class="text-muted align-middle">{{ comment.commentsLikes }}</span> -->
              </div>
            </div>
            <p v-html="filterComment(comment.comment)"></p>
          </div>
        </div>
      </div>
      <!-- <b-button
        :disabled="loadingComments"
        class="mb-1"
        v-if="loadedComments < totalComments"
        block
        variant="link"
        @click="loadComments"
        >{{ loadingComments ? "Loading..." : "Load more" }}</b-button
      > -->
      <!--/ comments -->

      <!-- comment box -->
      <div
        v-if="!$store.getters['auth/isAuth']"
        class="text-center py-1 text-muted"
      >
        <b-link to="/login">{{ $t("profileDetails.Login") }}</b-link>
        {{ $t("profileDetails.to_Comment") }}
      </div>
      <div v-if="$store.getters['auth/isAuth']">
        <b-form-group>
          <b-input-group>
            <b-form-textarea
              v-model="commentText"
              rows="1"
              :placeholder="$t('profileDetails.Add_Comment')"
              :disabled="isLoading"
              @keyup.enter="postComment"
            />
            <template #append v-if="commentText != ''">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                @click="postComment"
                size="sm"
                variant="primary"
                :disabled="isLoading"
                class="ml-1"
              >
                <feather-icon icon="SendIcon" size="20" />
              </b-button>
            </template>
          </b-input-group>
        </b-form-group>
        <!--/ comment box -->
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BCard,
  BCardText,
  BFormInput,
  BImg,
  BLink,
  BRow,
  BCol,
  BAvatarGroup,
  VBTooltip,
  BFormTextarea,
  BButton,
  BFormGroup,
  BEmbed,
  BInputGroup,
} from "bootstrap-vue";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import { kFormatter } from "@core/utils/filter";
import useHelper from "@/helper";
import moment from "moment";
import axios from "axios";
export default {
  components: {
    BAvatar,
    BInputGroup,
    BCard,
    BFormInput,
    BCardText,
    BButton,
    BFormTextarea,
    BImg,
    BFormGroup,
    BRow,
    BLink,
    BCol,
    BAvatarGroup,
    BEmbed,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },

  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },

    totalComments: "",
    profileId: "",
  },

  data() {
    return {
      commentText: "",
      youLiked: false,
      isLoading: false,
      // newtotalComments: "",

      allComments: 0,
      loadedComments: 0,
      pageComments: 1,
      loadingComments: false,
      showAllComments: true,
      totalComment2: this.totalComments,
    };
  },
  // props: {
  //   data: [],
  //   totalComments: "",
  //   profileId: "",
  // },
  // props: ["data", "totalComments", "profileId"],

  methods: {
    kFormatter,
    moment: function (input) {
      return moment(input).locale(this.$store.state.locale.locale);
    },
    showAllCommentsFun: function () {
      if (this.showAllComments) {
        this.showAllComments = false;
      } else {
        this.showAllComments = true;
      }
    },

    filterComment(text) {
      return text.replace("\n", "<br />");
    },

    deleteComment: async function (commentId) {
      try {
        this.$bvModal
          .msgBoxConfirm(this.$t("profileDetails.Are_sure_delete_comment"), {
            title: this.$t("profileDetails.Please_Confirm"),
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: this.$t("messages.YES"),
            cancelTitle: this.$t("messages.NO"),
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              const userData = new FormData();
              userData.append("profileId", this.profileId);
              const res = axios.delete(`comments/${commentId}`, {
                data: userData,
              });
              const tempComments = this.data.comments.filter(
                (x) => x._id != commentId
              );
              this.data.comments = tempComments;
              this.data.totalComments -= 1;
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
    // deleteComment: async function (commentId) {
    //   try {
    //     if (confirm(this.$i18n.t("profileDetails.Are_sure_delete_comment"))) {
    //       const userData = new FormData();
    //       userData.append("profileId", this.profileId);
    //       const res = await axios.delete(`comments/${commentId}`, {
    //         data: userData,
    //       });
    //       const tempComments = this.data.comments.filter(
    //         (x) => x._id != commentId
    //       );
    //       this.data.comments = tempComments;
    //       this.data.totalComments -= 1;
    //       // this.decreaseTotal();
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
    postComment: async function () {
      try {
        this.isLoading = true;
        const formData = new FormData();
        formData.append("profileId", this.profileId);
        formData.append("comment", this.commentText);
        const res = await axios.post("comments", formData);
        const tempComments = this.data.comments;
        tempComments.push({
          _id: res.data.data._id,
          comment: this.commentText,
          createdAt: res.data.data.createdAt,
          userId: {
            _id: res.data.data.userId._id,
            username: res.data.data.userId.username,
            profileImage: res.data.data.userId.profileImage,
          },
        });
        this.data.comments = tempComments;
        this.data.totalComments += 1;
        // this.increaseTotal();
        this.commentText = "";
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
      }
    },
    getTotal: function () {
      return this.totalComment2;
    },
    increaseTotal: async function () {
      // this.totalComment2 += 1;

      let myNumber = this.totalComments;
      myNumber++;
      this.$emit("totalComments", myNumber);
      this.totalComments++;
    },
    decreaseTotal: function () {
      let myNumber = this.totalComments;
      myNumber--;
      this.$emit("totalComments", myNumber);
    },
    // loadComments: async function () {
    //   try {
    //     this.loadingComments = true;
    //     const formData = new FormData();
    //     formData.append("profileId", this.data._id);
    //     formData.append("page", this.pageComments);
    //     formData.append("perPage", 3);
    //     const res = await axios.post("timeline/comments", formData);
    //     if (res.data.length) {
    //       this.loadedComments += res.data.length;
    //       this.data.comments = this.data.comments.concat(res.data);
    //       if (this.loadedComments < this.totalComments) {
    //         this.pageComments += 1;
    //       }
    //     } else {
    //       this.loadedComments = this.totalComments;
    //     }
    //     this.loadingComments = false;
    //   } catch (e) {
    //     console.log(e.message);
    //     alert("Unable to load Comments please try again later");
    //     this.loadingComments = false;
    //   }
    // },
  },
  setup(props) {
    const { getProfileTypeCoverImage } = useHelper();

    return {
      getProfileTypeCoverImage,
    };
    this.allComments = this.totalComments;
    this.loadedComments = this.data.length;
  },
  created() {
    // this.newtotalComments = this.totalComments;
    // this.loadedComments = this.data.length;
    // this.pageComments = this.data.length > 0 ? 2 : 1;
    // Check if user liked this post
    // this.youLiked = false;
    // if (
    //   store.getters["auth/isAuth"] &&
    //   store.getters["auth/user"] &&
    //   this.data.reactings.length
    // ) {
    //   const isUserLiked = this.data.reactings.find((user) => {
    //     return user.react.userId == store.getters["auth/user"]._id;
    //   });
    //   this.youLiked = isUserLiked ? true : false;
    // }
  },

  computed: {
    // getNewtotalComments: function () {
    //   return this.totalComments;
    // },
    // setNewtotalComments: function (value) {
    //   this.totalComments = this.totalComments + value;
    // },
    // mySelection: {
    //   get() {
    //     return this.totalComments;
    //   },
    //   set(v) {
    //     this.$emit("totalComments", v);
    //   },
    // },
  },
};
</script>
<style scoped>
.allComments {
  max-height: 350px;
  overflow-y: scroll;
}
</style>
