<template>
  <b-sidebar
    id="add-new-auction"
    class="mt-5 add-auction"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">{{ $t("profileDetails.Start_Auction") }}</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer ref="refFormObserver">
        <!-- Form -->
        <!-- @submit.prevent="handleSubmit(onSubmit)" -->

        <b-form class="p-2" @reset.prevent="resetForm">
          <!-- initialPrice -->
          <validation-provider
            class="mt-1"
            #default="validationContext"
            name="initial price"
            rules="required|integer"
          >
            <b-form-group>
              <label for="initial-price" class="required">{{
                $t("profileDetails.Initial_price")
              }}</label>
              <b-form-input
                id="initial-price"
                v-model="auction.initialPrice"
                type="number"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                :placeholder="$t('profileDetails.Initial_price')"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Currency -->
          <validation-provider
            class="mt-1"
            #default="validationContext"
            name="Currency"
            rules="required"
          >
            <b-form-group>
              <label for="currency" class="required">{{
                $t("profileDetails.Currency")
              }}</label>

              <v-select
                id="currency"
                v-model="auction.currency"
                :options="currencies"
                autofocus
                :selectable="(option) => !option.value.includes('select_value')"
                :state="getValidationState(validationContext)"
                label="text"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- description -->
          <validation-provider
            class="mt-1"
            #default="validationContext"
            name="description"
            :rules="`max:${$maxDescriptionAuction}`"
          >
            <b-form-group
              :label="$t('profileDetails.Brief_description')"
              label-for="description"
            >
              <b-form-textarea
                id="description"
                v-model="auction.description"
                autofocus
                :state="getValidationState(validationContext)"
                :placeholder="$t('profileDetails.Brief_description')"
                rows="2"
                max-rows="2"
                no-auto-shrink
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <b-form-group>
            <b-form-checkbox
              v-model="checkbox"
              id="checkbox-9"
              name="checkbox-9"
              @input="showEndAuction"
            >
              {{ $t("profileDetails.End_Auction") }}
            </b-form-checkbox>
          </b-form-group>

          <!-- End Auction -->
          <validation-provider
            class="mt-1"
            #default="validationContext"
            name="End Auction"
            rules=""
            v-if="endAuction"
          >
            <b-form-group
              :label="$t('profileDetails.Select_end_auction')"
              label-for="endAuction"
            >
              <datetime
                v-model="auction.endDate"
                type="datetime"
                format="yyyy-MM-dd HH:mm:ss"
                v-if="endAuction"
              ></datetime>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- <div>
            <b-form-timepicker v-model="auction.endTime" locale="en" />
            <div class="mt-2">Value: '{{ auction.endTime }}'</div>
          </div> -->

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              @click.prevent="validationForm"
            >
              {{ $t("profileDetails.Start") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t("profileDetails.Cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { Datetime } from "vue-datetime";
// You need a specific loader for CSS files
import "vue-datetime/dist/vue-datetime.css";
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormDatepicker,
  BFormCheckbox,
  BCol,
  BFormTextarea,
  BFormTimepicker,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import countries from "@/@fake-db/data/other/countries";
import store from "@/store";
import { mapActions, mapGetters } from "vuex";

import "../../../../layouts/landpage/ValidationFrom.js";

export default {
  components: {
    Datetime,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormCheckbox,
    BCol,
    BFormTextarea,
    BFormTimepicker,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewUserSidebarActive",
    event: "update:is-add-new-user-sidebar-active",
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    profileId: {},
    // roleOptions: {
    //   type: Array,
    //   required: true,
    // },
    // planOptions: {
    //   type: Array,
    //   required: true,
    // },
  },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const minDate = new Date(today);
    return {
      required,
      alphaNum,
      email,
      endAuction: false,
      checkbox: false,
      minDate: minDate,
      auction: {
        initialPrice: "",
        currency: "",
        endDate: "",
        endTime: "",
        description: "",
      },
    };
  },
  setup(props, { emit }) {
    const blankUserData = {
      fullName: "",
      username: "",
      email: "",
      role: null,
      currentPlan: null,
      company: "",
      country: "",
      contact: "",
    };

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)));
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData));
    };

    const onSubmit = () => {
      //serviceProfile.files[0].fileUrl
      // store.dispatch("app-user/addUser", userData.value).then(() => {
      //   emit("refetch-data");
      //   emit("update:is-add-new-user-sidebar-active", false);
      // });
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);

    return {
      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  computed: {
    ...mapGetters({
      currencies: "currency/getCurrencies",
      user: "auth/user",
    }),
  },
  methods: {
    ...mapActions({
      //createService: "profile/createService",
      fetchCurrencies: "currency/retreiveCurrencies",
    }),
    showEndAuction() {
      this.endAuction = !this.endAuction;
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.refFormObserver.validate().then((success) => {
          if (success) {
            this.$bvModal
              .msgBoxConfirm(this.$t("profileDetails.Are_sure_start_auction"), {
                title: this.$t("profileDetails.Please_Confirm"),
                size: "sm",
                buttonSize: "sm",
                okVariant: "success",
                okTitle: this.$t("messages.YES"),
                cancelTitle: this.$t("messages.NO"),
                footerClass: "p-2",
                hideHeaderClose: false,
                centered: true,
              })
              .then((value) => {
                if (value) {
                  const auctionData = new FormData();
                  auctionData.append("buyerId", this.user._id);
                  auctionData.append("profileId", this.profileId);
                  auctionData.append("initialPrice", this.auction.initialPrice);
                  auctionData.append("lastPrice", this.auction.initialPrice);
                  auctionData.append("currencyId", this.auction.currency.value);
                  auctionData.append("startDate", new Date());
                  if (this.auction.description) {
                    auctionData.append("description", this.auction.description);
                  }
                  if (this.checkbox) {
                    auctionData.append("endDate", this.auction.endDate);
                    auctionData.append("type", "schedule");
                  } else {
                    auctionData.append("type", "open");
                  }
                  auctionData.append("belongFor", "service");

                  axios.post("auctions", auctionData);
                  window.location.reload(true);
                }
              });
          } else {
            reject();
          }
        });
      });
    },
  },
  mounted() {
    this.fetchCurrencies();
    this.configValidate();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
<style>
.b-sidebar > .b-sidebar-body {
  margin-top: 42px;
}
.required:after {
  content: " *";
  color: red;
}

.vdatetime-input {
  width: 100%;
}
[dir] label {
  font-weight: 500;
  font-size: 15px;
}
</style>
