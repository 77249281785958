<template>
  <div class="mx-auto">
    <div>
      <!-- <b-row class="pb-50 mt-50 border-top pt-1">
        <b-col
          sm="4"
          class="d-flex justify-content-between justify-content-sm-end align-items-center mb-2"
        >
        </b-col>
      </b-row> -->
      <div class="d-flex flex-column border-bottom divPrices">
        <h6 class="mb-2 text-muted">
          {{ $t("profileDetails.Initial_price") }} :
          <span class="text-darkBlue"
            >{{
              $store.state.locale.locale == "en"
                ? calcPrice(auctionId.initialPrice) +
                  " " +
                  auctionId.currencyId.name
                : arCalcPrice(auctionId.initialPrice) +
                  " " +
                  auctionId.currencyId.arabicName
            }}
            {{
              $store.state.locale.locale == "en"
                ? " ≃ " +
                  calcPrice(
                    currencyExchange(
                      auctionId.initialPrice,
                      auctionId.currencyId.symbol
                    )
                  )
                : " ≃ " +
                  arCalcPrice(
                    currencyExchange(
                      auctionId.initialPrice,
                      auctionId.currencyId.symbol
                    )
                  )
            }}
            <span>{{ $store.state.myCurrency.myCurrency.symbol }}</span>
          </span>
        </h6>
        <h5 class="mb-2 text-muted">
          {{ $t("profileDetails.Last_price") }} :
          <span class="text-darkBlue"
            >{{
              $store.state.locale.locale == "en"
                ? calcPrice(auctionId.lastPrice) +
                  " " +
                  auctionId.currencyId.name
                : arCalcPrice(auctionId.lastPrice) +
                  " " +
                  auctionId.currencyId.arabicName
            }}
            {{
              $store.state.locale.locale == "en"
                ? " ≃ " +
                  calcPrice(
                    currencyExchange(
                      auctionId.lastPrice,
                      auctionId.currencyId.symbol
                    )
                  )
                : " ≃ " +
                  arCalcPrice(
                    currencyExchange(
                      auctionId.lastPrice,
                      auctionId.currencyId.symbol
                    )
                  )
            }}
            <span>{{ $store.state.myCurrency.myCurrency.symbol }}</span>
          </span>
        </h5>
      </div>
      <h6
        v-if="auctionId.description"
        class="mt-1 text-muted border-bottom pb-2"
      >
        {{ $t("profileDetails.Brief_description") }} :
        <h6 class="text-darkBlue pt-1">{{ auctionId.description }}</h6>
      </h6>

      <div v-if="this.AuctionStatus != 'closed'">
        <div
          v-if="!$store.getters['auth/isAuth']"
          class="text-center py-1 text-muted"
        >
          <b-link to="/login">{{ $t("profileDetails.Login") }} </b-link>
          {{ $t("profileDetails.toBid") }}
        </div>
        <div v-if="$store.getters['auth/isAuth']">
          <div v-if="subscription">
            <p class="text-muted" v-if="bidsArray === 0">
              {{ $t("profileDetails.Be_first_bid") }}
            </p>
            <b-form-group>
              <b-input-group>
                <b-form-input
                  v-model="bidNumber"
                  rows="1"
                  type="number"
                  :placeholder="$t('profileDetails.Add_Bid')"
                  :disabled="isLoading"
                  @keyup.enter="postBid"
                />
                <template #append v-if="bidNumber != ''">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    @click="postBid"
                    size="sm"
                    variant="primary"
                    :disabled="isLoading"
                    class="ml-1"
                  >
                    <feather-icon icon="SendIcon" size="20" />
                  </b-button>
                </template>
              </b-input-group>
              <span class="color-red">
                {{ $t("profileDetails.Note") }}
                {{
                  $store.state.locale.locale == "en"
                    ? auctionId.currencyId.name
                    : auctionId.currencyId.arabicName
                }}</span
              >
            </b-form-group>
          </div>
          <div v-else class="">
            <span class="text-center py-1 text-muted"
              >{{ $t("profileDetails.UpgradePlan") }}
            </span>
            <b-link to="/pricing"
              ><span>{{ $t("profileDetails.fromHere") }}</span></b-link
            >
          </div>
        </div>
      </div>
      <div v-else>
        <hr />
        <!-- <h3 class="text-center mb-2">Auction is over</h3> -->
      </div>
      <!-- comments -->
      <div class="bids-comments">
        <div
          v-for="(bid, ind) in reverseItems"
          :key="ind"
          class="d-flex align-items-start mb-1"
        >
          <b-link :to="`/profile/${bid.bidderId.username}`"
            ><b-avatar
              :src="bid.bidderId.profileImage"
              size="34"
              class="mt-25 mr-75"
          /></b-link>
          <div class="profile-user-info w-100">
            <div class="d-flex align-items-center justify-content-between">
              <h6 class="mb-0">
                <b-link :to="`/profile/${bid.bidderId.username}`">{{
                  bid.bidderId.username
                }}</b-link>
                ⸱
                <span class="text-muted">{{
                  moment(bid.createdAt).fromNow(false)
                }}</span>
              </h6>

              <!-- <div class="text-body">
              <feather-icon
                v-if="
                  $store.getters['auth/isAuth'] &&
                  $store.getters['auth/user']._id == comment.userId._id
                "
                icon="TrashIcon"
                size="18"
                class="mr-25"
                :class="comment.youLiked ? 'profile-likes' : 'profile-icon'"
                @click="deleteComment(comment._id)"
              />
            </div> -->
            </div>
            <p>{{ bid.bidNumber }}</p>
          </div>
        </div>
      </div>
      <!-- <b-button
        :disabled="loadingComments"
        class="mb-1"
        v-if="loadedBids < totalBids"
        block
        variant="link"
        @click="loadComments"
        >{{ loadingComments ? "Loading..." : "Load more" }}</b-button
      > -->
      <!--/ comments -->
      <!-- comment box -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import {
  BAvatar,
  BCard,
  BCardText,
  BFormInput,
  BImg,
  BLink,
  BRow,
  BCol,
  BAvatarGroup,
  VBTooltip,
  BFormTextarea,
  BButton,
  BFormGroup,
  BEmbed,
  BInputGroup,
} from "bootstrap-vue";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import { kFormatter } from "@core/utils/filter";
import useHelper from "@/helper";
import moment from "moment";
import axios from "axios";
import millify from "millify";

export default {
  components: {
    BAvatar,
    BInputGroup,
    BCard,
    BFormInput,
    BCardText,
    BButton,
    BFormTextarea,
    BImg,
    BFormGroup,
    BRow,
    BLink,
    BCol,
    BAvatarGroup,
    BEmbed,
    ToastificationContent,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      bidNumber: "",
      youLiked: false,
      isLoading: false,

      initialPrice: 0,
      lastPrice: 0,
      bidsArray: 0,
      lastPriceMe: 0,
      totalBids: null,
      loadedBids: null,
      pageComments: 1,
      loadingComments: false,
      bookmarked: false,

      checkalertMes: false,

      subscription: false,
    };
  },
  props: {
    // data: {
    //   type: Object,
    //   default: {},
    // },
    auctionId: {},
    AuctionOver: null,
    AuctionStatus: "",
  },
  methods: {
    kFormatter,
    moment: function (input) {
      return moment(input).locale(this.$store.state.locale.locale);
    },

    filterComment(text) {
      return text.replace("\n", "<br />");
    },

    // deleteComment: async function (commentId) {
    //   try {
    //     if (confirm("Are you sure you want to delete this comment ?")) {
    //       const userData = new FormData();
    //       userData.append("profileId", this.data._id);
    //       const res = await axios.delete(`comments/${commentId}`, {
    //         data: userData,
    //       });
    //       const tempComments = this.data.comments.filter(
    //         (x) => x._id != commentId
    //       );
    //       // this.data.comments = tempComments;
    //       // this.data.totalBids -= 1;
    //     }
    //   } catch (error) {}
    // },
    postBid: async function () {
      const arrayLength = this.auctionId.bids.length;
      try {
        // if (confirm(this.$t("profileDetails.Are_sure_participate_auction"))) {
        this.$bvModal
          .msgBoxConfirm(
            this.$t("profileDetails.Are_sure_participate_auction"),
            {
              title: this.$t("profileDetails.Please_Confirm"),
              size: "sm",
              buttonSize: "sm",
              okVariant: "success",
              okTitle: this.$t("messages.YES"),
              cancelTitle: this.$t("messages.NO"),
              footerClass: "p-2",
              hideHeaderClose: false,
              centered: true,
            }
          )
          .then((value) => {
            if (value) {
              if (this.bidNumber > this.auctionId.lastPrice) {
                this.isLoading = true;
                const formData = new FormData();
                formData.append("auctionId", this.auctionId._id);
                formData.append("bidNumber", this.bidNumber);
                const res = axios
                  .post("bids", formData)
                  .then(() => {
                    // this.totalBids += 1;
                    // console.log(res.data.data);
                    const tempBids = this.auctionId.bids;
                    tempBids.push({
                      _id: res.data.data._id,
                      bidNumber: this.bidNumber,
                      bidderId: {
                        _id: res.data.data.sellerId,
                        username: this.user.username,
                        profileImage: "false",
                      },
                      createdAt: new Date(),
                    });
                    // console.log(tempBids);
                    this.auctionId.bids = tempBids;
                    this.auctionId.lastPrice = this.bidNumber;
                    this.bidNumber = null;
                    // this.bidNumber.focus();
                    this.isLoading = false;
                    // this.lastPriceMe = this.bidNumber;
                  })
                  .catch((error) => {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: error.response.data.error.message,
                        icon: "AlertTriangleIcon",
                        variant: "danger",
                      },
                    });
                    this.bidNumber = "";
                    // console.log(error.response.data.error.message);
                  });
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: this.$t("profileDetails.enter_higher_price"),
                    icon: "AlertTriangleIcon",
                    variant: "danger",
                  },
                });
                this.bidNumber = "";
              }
            }
          });
        // if (arrayLength > 0) {
        // }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: error.response.data.error.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
        this.isLoading = false;
      }
    },

    // addBid() {
    //   this.$bvModal
    //     .msgBoxConfirm("Please confirm that you want to delete file.", {
    //       title: "Please Confirm",
    //       size: "sm",
    //       buttonSize: "sm",
    //       okVariant: "danger",
    //       okTitle: "YES",
    //       cancelTitle: "NO",
    //       footerClass: "p-2",
    //       hideHeaderClose: false,
    //       centered: true,
    //     })
    //     .then((value) => {
    //       if (value) {
    //         try {
    //           if (this.auctionId.bids.length > 0) {
    //             console.log(this.auctionId.bids.length);
    //             if (
    //               this.bidNumber >
    //                 this.auctionId.bids[this.auctionId.bids.length - 1]
    //                   .bidNumber &&
    //               this.bidNumber > this.lastPriceMe
    //             ) {
    //               this.isLoading = true;
    //               const formData = new FormData();
    //               formData.append("auctionId", this.auctionId._id);
    //               formData.append("bidNumber", this.bidNumber);
    //               // const res =  await axios.post("bids", formData);
    //               this.totalBids += 1;
    //               // console.log(res.data.data);
    //               const tempBids = this.auctionId.bids;
    //               tempBids.push({
    //                 _id: res.data.data._id,
    //                 bidNumber: this.bidNumber,
    //                 bidderId: {
    //                   _id: res.data.data.sellerId,
    //                   username: this.user.username,
    //                   profileImage: "false",
    //                 },
    //                 createdAt: new Date(),
    //               });
    //               // console.log(tempBids);
    //               this.auctionId.bids = tempBids;

    //               this.bidNumber = null;
    //               // this.bidNumber.focus();
    //               this.isLoading = false;
    //               this.lastPriceMe = this.bidNumber;
    //             }
    //           } else {
    //             console.log(this.auctionId.bids.length);

    //             if (this.bidNumber > this.auctionId.initialPrice) {
    //               this.isLoading = true;
    //               const formData = new FormData();
    //               formData.append("auctionId", this.auctionId._id);
    //               formData.append("bidNumber", this.bidNumber);
    //               // const res =  await axios.post("bids", formData);
    //               this.totalBids += 1;
    //               // console.log(res.data.data);
    //               const tempBids = this.auctionId.bids;
    //               tempBids.push({
    //                 _id: res.data.data._id,
    //                 bidNumber: this.bidNumber,
    //                 bidderId: {
    //                   _id: res.data.data.sellerId,
    //                   username: this.user.username,
    //                   profileImage: "false",
    //                 },
    //                 createdAt: new Date(),
    //               });
    //               // console.log(tempBids);
    //               this.auctionId.bids = tempBids;

    //               this.bidNumber = null;
    //               // this.bidNumber.focus();
    //               this.isLoading = false;
    //               this.lastPriceMe = this.bidNumber;
    //             }
    //           }
    //         } catch (err) {
    //           this.isLoading = false;
    //         }
    //       }
    //     })
    //     .catch((err) => {});
    // },
    checkalert() {
      this.$bvModal
        .msgBoxConfirm(this.$t("profileDetails.Are_sure_participate_auction"), {
          title: this.$t("profileDetails.Please_Confirm"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          okTitle: this.$t("messages.YES"),
          cancelTitle: this.$t("messages.NO"),
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.checkalertMes = true;
          }
        });
    },

    // loadComments: async function () {
    //   try {
    //     this.loadingComments = true;
    //     const formData = new FormData();
    //     formData.append("profileId", this.data._id);
    //     formData.append("page", this.pageComments);
    //     formData.append("perPage", 3);
    //     const res = await axios.post("timeline/comments", formData);
    //     if (res.data.length) {
    //       this.loadedBids += res.data.length;
    //       this.data.comments = this.data.comments.concat(res.data);
    //       if (this.loadedBids < this.totalBids) {
    //         this.pageComments += 1;
    //       }
    //     } else {
    //       this.loadedBids = this.totalBids;
    //     }
    //     this.loadingComments = false;
    //   } catch (e) {
    //     alert("Unable to load Bids please try again later");
    //     this.loadingComments = false;
    //   }
    // },

    checkSubscrition() {
      let mySubscription;
      let sub = this.user.subscriptions;
      // console.log(sub.length);
      if (sub != undefined && sub.length > 0) {
        mySubscription = sub[sub.length - 1];
        // console.log(mySubscription.planName.toLowerCase());
        if (mySubscription.planName.toLowerCase() === "platinum") {
          this.subscription = true;
        }
        //  else {
        //   this.subscription = false;
        // }
      }
    },

    calcPrice(number) {
      return `${millify(number, {
        precision: 1,
        decimalSeparator: ".",
        space: true,
      })} `;
    },
    arCalcPrice(number) {
      return `${millify(number, {
        precision: 1,
        units: ["", "الف", "مليون", "مليار", "بليون"],
        decimalSeparator: ".",
        space: true,
      })} `;
    },
  },
  setup(props) {
    const { getProfileTypeCoverImage } = useHelper();

    return {
      getProfileTypeCoverImage,
    };
  },
  async created() {
    // check if user bookmarked this post, we will mark as the bookmarked so it will be highlighted
  },
  computed: {
    reverseItems() {
      return this.auctionId.bids.slice().reverse();
    },
    ...mapGetters({
      user: "auth/user",
    }),
  },
  mounted() {
    this.initialPrice = this.auctionId.initialPrice;
    this.bidsArray = this.auctionId.bids.length;
    if (this.auctionId.bids.length > 0) {
      this.lastPrice =
        this.auctionId.bids[this.auctionId.bids.length - 1].bidNumber;
      // console.log(this.lastPrice);
    } else {
      this.lastPrice = this.auctionId.initialPrice;
    }

    this.totalBids = this.auctionId.bids.length;
    this.loadedBids = this.auctionId.bids.length;
    this.pageComments = this.auctionId.bids.length > 0 ? 2 : 1;
    this.checkSubscrition();
  },
};
</script>
<style scoped>
.btn-bookmark {
  position: absolute;
  right: 10px;
  top: 0px;
  border: none;
  padding: 10px 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  transition: 0.3s linear all;
}
.btn-bookmark svg {
  transition: 0.2s linear all;
}
.btn-bookmark.btn-bookmarked svg {
  fill: #ffc845;
  stroke: #d09d23;
}
.bids-comments {
  max-height: 250px;
  overflow-y: scroll;
}
.text-darkBlue {
  color: #062455;
}
@media (max-width: 550px) {
  .divPrices {
    display: flex;
    flex-direction: column;
  }
  .divPrices h6 {
    text-align: start;
  }
}
.color-red {
  color: #ef0000;
}
</style>
