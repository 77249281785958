<template>
  <b-container class="bv-example-row">
    <b-row v-show="loading">
      <b-col cols="12" md="7" sm="12">
        <b-card>
          <b-row>
            <b-col md="3">
              <b-skeleton-img height="150" />
            </b-col>
            <b-col md="9">
              <b-skeleton animation="fade" width="55%"></b-skeleton>
              <b-skeleton animation="fade" width="75%"></b-skeleton>
              <b-skeleton animation="fade" width="85%"></b-skeleton>
            </b-col>
          </b-row>
        </b-card>
        <b-card>
          <b-skeleton-table
            :rows="7"
            :columns="2"
            :table-props="{ bordered: true, striped: true }"
          ></b-skeleton-table>
          <div class="mt-2">
            <b-skeleton animation="wave" width="85%"></b-skeleton>
            <b-skeleton animation="wave" width="55%"></b-skeleton>
            <b-skeleton animation="wave" width="70%"></b-skeleton>
          </div>
        </b-card>
      </b-col>
      <b-col md="5">
        <b-card>
          <b-skeleton animation="wave" width="85%"></b-skeleton>
          <b-skeleton animation="wave" width="55%"></b-skeleton>
          <b-skeleton animation="wave" width="70%"></b-skeleton>
          <b-skeleton animation="wave" width="90%"></b-skeleton>
          <div class="d-flex mt-2">
            <b-skeleton type="avatar"></b-skeleton>
            <b-skeleton type="input" width="100%" class="ml-2"></b-skeleton>
          </div>
          <div class="d-flex mt-1">
            <b-skeleton type="avatar"></b-skeleton>
            <b-skeleton type="input" width="100%" class="ml-2"></b-skeleton>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BRow,
  BSkeleton,
  BSkeletonTable,
  BCol,
  BSkeletonImg,
  BContainer,
  BCard,
} from "bootstrap-vue";

export default {
  name: "SkeletonList",
  components: {
    BRow,
    BCol,
    BContainer,
    BSkeletonImg,
    BSkeleton,
    BCard,
    BSkeletonTable,
  },
  props: {
    loading: {
      type: Boolean,
    },
    numberInRow: {
      type: Number,
    },
    itemNumber: {
      type: Number,
    },
    height: {
      type: String,
    },
  },
};
</script>

<style lang="CSS" scoped></style>
